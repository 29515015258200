import '../styles/nav.scss';
function Footer (){
 
    return (
        <footer>
            <p>Asbah Ashfaq - Full Stack Developer | Sydney, Australia</p>
        </footer>
    )
}

export default Footer;